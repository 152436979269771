import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';

import { AppComponent } from './app.component';

const firebaseConfig = {
  apiKey: 'AIzaSyDZqKAE_yW06qy_H9wQML-FyuDFlaoWqDo',
  authDomain: 'village-sourdough.firebaseapp.com',
  databaseURL: 'https://village-sourdough.firebaseio.com',
  projectId: 'village-sourdough',
  storageBucket: 'village-sourdough.appspot.com',
  messagingSenderId: '1087099783845',
  appId: '1:1087099783845:web:7e53448703d7feff'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(firebaseConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
